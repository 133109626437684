<script setup lang="ts">
import { MessageCircleQuestion } from 'lucide-vue-next';
import { iconColors } from 'assets/js/utils';

interface Props {
  isBottomOffset: boolean;
}

withDefaults(defineProps<Props>(), {
  isBottomOffset: false,
});

function open() {
  if (window.jivo_api) {
    window.jivo_api.open();
  } else {
    alert(
      'Похоже, что у вас включен блокировщик рекламы, сайт может работать некорректно. Добавьте сайт begemot.ai в исключения или выключите блокировщик рекламы и обновите страницу.'
    );
  }
}
</script>

<template>
  <client-only>
    <button
      class="jivo-button"
      :class="{ 'bottom-offset': isBottomOffset }"
      @click="open()"
    >
      <MessageCircleQuestion
        :size="28"
        :color="iconColors.primary"
      />
    </button>
  </client-only>
</template>

<style scoped lang="scss">
.jivo-button {
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  position: fixed;
  transition: opacity 0.1s;
  visibility: visible;
  z-index: 2147483647;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  border: 1px solid $new-line-stroke;
  background: #fff;
  box-shadow: 0px 4px 26px 0px $new-line-theme-fade;
  cursor: pointer;
  @include media-breakpoint-up(xl) {
    display: none;
  }

  &.bottom-offset {
    bottom: 100px;
  }

  &:focus {
    outline: none;
  }
}
</style>
