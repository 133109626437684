<script lang="ts" setup>
import VHeader from '~/components/new/VHeader.vue';
import VFooter from '~/components/new/VFooter.vue';
import JivoButton from '~/components/new/JivoButton.vue';
import { useJivoStore } from '~/stores/JivoStore';
import { getUserProjects } from '~/api/user';

const route = useRoute();
const jivoStore = useJivoStore();
const cClass = computed(() => {
  if (route.name === 'index' || route.name === 'course-work' || route.name === 'referat' || route.name === 'text') {
    jivoStore.isBottomOffset = false;
    return 'transparent';
  }
  if (route.name === 'projects-id') {
    return 'white-line';
  }
  jivoStore.isBottomOffset = false;
  return 'white';
});
</script>

<template>
  <div>
    <v-header :schema="cClass" />
    <main>
      <slot />
    </main>
    <jivo-button :is-bottom-offset="jivoStore.isBottomOffset" />
    <v-footer />
  </div>
</template>

<style lang="scss"></style>
